import Vue from "vue";
import Router from "vue-router";
import VueRouteMiddleware from "vue-route-middleware";
import { auth, guest } from "src/router/middleware";
import i18n from "src/services/i18n";
Vue.use(Router);

const routes = [
  //#region Page Not Found
  {
    path: "*",
    name: "Error404",
    component: () => import("src/errors/error_404"),
    meta: {
      title: "Error404",
    },
  },
  //#endregion
  //#region Common routes
  {
    path: "/",
    component: () => import("src/components/body"),
    children: [
      //#region Dashboard
      {
        path: "",
        name: "Home",
        component: () => import("src/views/admin/Home"),
        meta: {
          title: "Home",
          middleware: [auth],
        },
      },
      //#endregion

      {
        path: "/test",
        name: "Test",
        component: () => import("src/views/admin/test/Test"),
      },

      //#region Password change
      {
        path: "/password/change",
        name: "PasswordChange",
        component: () => import("src/views/auth/PasswordChange"),
        meta: {
          title: "Password Change",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Users
      {
        path: "/users",
        name: "UserList",
        component: () => import("src/views/admin/user/UserList"),
        meta: {
          title: "User List",
          middleware: [auth],
        },
      },
      {
        path: "/users/:id",
        name: "UserDetail",
        component: () => import("src/views/admin/user/UserDetail"),
        meta: {
          title: "User Detail",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Companies
      {
        path: "/companies/add",
        name: "CompanyAdd",
        component: () => import("src/views/admin/company/CompanyAdd"),
        meta: {
          title: "Company Add",
          middleware: [auth],
        },
      },
      {
        path: "/companies",
        name: "CompanyList",
        component: () => import("src/views/admin/company/CompanyList"),
        meta: {
          title: "Company List",
          middleware: [auth],
        },
      },
      {
        path: "/companies/:id/detail",
        name: "CompanyDetail",
        component: () => import("src/views/admin/company/CompanyDetail"),
        meta: {
          title: "Company Detail",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Storage
      {
        path: "/storages",
        name: "StorageList",
        component: () => import("src/views/admin/storage/StorageList"),
        meta: {
          title: "List Storage",
          middleware: [auth],
        },
      },
      {
        path: "/storages/add",
        name: "AddStorage",
        component: () => import("src/views/admin/storage/StorageAdd"),
        meta: {
          title: "Add Storage",
          middleware: [auth],
        },
      },
      {
        path: "/storages/:id",
        name: "StorageDetail",
        component: () => import("src/views/admin/storage/StorageDetail"),
        meta: {
          title: "Storage Detail",
          middleware: [auth],
        },
      },
      {
        path: "/storages/:id/edit",
        name: "StorageEdit",
        component: () => import("src/views/admin/storage/StorageEdit"),
        meta: {
          title: "Storage Edit",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Groups
      {
        path: "/groups/:id",
        name: "GroupDetail",
        component: () => import("src/views/admin/group/GroupDetail"),
        meta: {
          title: "Group Detail",
          middleware: [auth],
        },
      },
      {
        path: "/groups",
        name: "GroupList",
        component: () => import("src/views/admin/group/GroupList"),
        meta: {
          title: "Group List",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Plans
      {
        path: "/plans",
        name: "PlanList",
        component: () => import("src/views/admin/plan/PlanList"),
        meta: {
          title: "Plan List",
          middleware: [auth],
        },
      },
      {
        path: "/plans/add",
        name: "PlanAdd",
        component: () => import("src/views/admin/plan/PlanAdd"),
        meta: {
          title: "Plan Add",
          middleware: [auth],
        },
      },
      {
        path: "/plans/:id",
        name: "PlanDetail",
        component: () => import("src/views/admin/plan/PlanDetail"),
        meta: {
          title: "Plan Detail",
          middleware: [auth],
        },
      },
      {
        path: "/plans/:id/edit",
        name: "PlanEdit",
        component: () => import("src/views/admin/plan/PlanEdit"),
        meta: {
          title: "Plan Edit",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Gold Management
      {
        path: "/gold-management/transaction-history",
        name: "GoldTransactionHistory",
        component: () =>
          import("src/views/admin/gold-management/GoldTransactionHistory"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/gold-management/user-analysis",
        name: "GoldUserAnalysis",
        component: () =>
          import("src/views/admin/gold-management/GoldUserAnalysis"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region Messages
      {
        path: "/messages",
        name: "MessageList",
        component: () => import("src/views/admin/message/MessageList"),
        meta: {
          title: "Message List",
          middleware: [auth],
        },
      },
      {
        path: "/messages/send",
        name: "SendMessage",
        component: () => import("src/views/admin/message/SendMessage"),
        meta: {
          title: "Send Message",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Payments
      {
        path: "/payments/:id",
        name: "PaymentDetail",
        component: () => import("src/views/admin/payment/PaymentDetail"),
        meta: {
          title: "Payment Detail",
          middleware: [auth],
        },
      },
      {
        path: "/payments",
        name: "PaymentHistory",
        component: () => import("src/views/admin/payment/PaymentHistory"),
        meta: {
          title: "Payment History",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Trials
      {
        path: "/trial",
        name: "TrialAdd",
        component: () => import("src/views/admin/plan/TrialAdd"),
        meta: {
          title: "Trial Add",
          middleware: [auth],
        },
      },
      {
        path: "/trial/:id/edit",
        name: "TrialEdit",
        component: () => import("src/views/admin/plan/TrialEdit"),
        meta: {
          title: "Trial Edit",
          middleware: [auth],
        },
      },
      {
        path: "/trial/:id",
        name: "TrialDetail",
        component: () => import("src/views/admin/plan/TrialDetail"),
        meta: {
          title: "Trial Detail",
          middleware: [auth],
        },
      },
      //#endregion

      //#region PR Channels
      {
        path: "/pr-channels",
        name: "PRChannelList",
        component: () => import("src/views/admin/prchannel/PRChannelList"),
        meta: {
          title: "PRChannel List",
          middleware: [auth],
        },
      },
      {
        path: "/pr-channels/:id",
        name: "PRChannelDetail",
        component: () => import("src/views/admin/prchannel/PRChannelDetail"),
        props: true,
        meta: {
          title: "PRChannel Detail",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Taxes
      {
        path: "/tax-zones-and-rates",
        name: "TaxZonesAndRates",
        component: () =>
          import("src/views/admin/taxes/tax-zones-and-rates/TaxZonesAndRates"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/tax-rate/add",
        name: "TaxRateAdd",
        component: () =>
          import("src/views/admin/taxes/tax-zones-and-rates/TaxRateAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/tax-rate/edit/:id",
        name: "TaxRateEdit",
        component: () =>
          import("src/views/admin/taxes/tax-zones-and-rates/TaxRateEdit"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/tax-rate/history",
        name: "TaxZonesAndRatesHistory",
        component: () =>
          import("src/views/admin/taxes/tax-zones-and-rates/TaxZonesAndRatesHistory"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      /*{
        path: "/users/:id/contact",
        name: "ContactList",
        component: () => import("src/views/admin/user/ContactList"),
        meta: {
          title: "Contact List",
          middleware: [auth],

        },
      },*/

      //#region Annouce
      {
        path: "/announces",
        name: "Annouces",
        component: () => import("src/views/admin/announce/AnnounceList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/announce/add",
        name: "AnnounceAdd",
        component: () => import("src/views/admin/announce/AnnounceAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/announce/edit/:id",
        name: "AnnounceEdit",
        component: () => import("src/views/admin/announce/AnnounceEdit"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region Mail Template
      {
        path: "/email-templates",
        name: "EmailTemplates",
        component: () =>
          import("src/views/admin/email-template/EmailTemplateList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/email-template/add",
        name: "EmailTemplateAdd",
        component: () =>
          import("src/views/admin/email-template/EmailTemplateAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/email-template/edit/:id",
        name: "EmailTemplateEdit",
        component: () =>
          import("src/views/admin/email-template/EmailTemplateEdit"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/email-sms/merge-tags",
        name: "MergeTags",
        component: () => import("src/views/admin/merge-tags/MergeTags"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region Sms
      {
        path: "sms-management",
        name: "SmsList",
        component: () => import("src/views/admin/sms-management/SMSList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "sms/add",
        name: "SmsAdd",
        component: () => import("src/views/admin/sms-management/SMSAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "sms/edit/:id",
        name: "SmsEdit",
        component: () => import("src/views/admin/sms-management/SMSEdit"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region Pages
      {
        path: "/pages",
        name: "Pages",
        component: () => import("src/views/admin/page/PageList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/page/add",
        name: "PageAdd",
        component: () => import("src/views/admin/page/PageAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/page/edit/:id",
        name: "PageEdit",
        component: () => import("src/views/admin/page/PageEdit"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region Languages
      {
        path: "/languages",
        name: "Languages",
        component: () => import("src/views/admin/language/LanguageList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/language/add",
        name: "LanguageAdd",
        component: () => import("src/views/admin/language/LanguageAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/language/edit",
        name: "LanguageEdit",
        component: () => import("src/views/admin/language/LanguageEdit"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/language/keywords",
        name: "KeywordManagement",
        component: () => import("src/views/admin/language/KeywordManagement"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region stickers
      {
        path: "/stickers",
        name: "Stickers",
        component: () => import("src/views/admin/sticker/StickerList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/sticker/add",
        name: "StickerAdd",
        component: () => import("src/views/admin/sticker/StickerAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/sticker/edit/:id",
        name: "StickerEdit",
        component: () => import("src/views/admin/sticker/StickerEdit"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region Clothes
      {
        path: "/clothes",
        name: "ClothesList",
        component: () => import("src/views/admin/clothes/ClothesList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clothes/add",
        name: "ClothesAdd",
        component: () => import("src/views/admin/clothes/ClothesAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clothes/edit/:id",
        name: "ClothesEdit",
        component: () => import("src/views/admin/clothes/ClothesEdit"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion


      //#region Rooms
      {
        path: "/rooms",
        name: "RoomList",
        component: () => import("src/views/admin/rooms/RoomList"),
        meta: {
          middleware: [auth],
        },
      },
      // {
      //   path: "/rooms/add",
      //   name: "RoomAdd",
      //   component: () => import("src/views/admin/rooms/RoomAdd"),
      // },
      {
        path: "/sample-rooms",
        name: "SampleRoomList",
        component: () => import("src/views/admin/rooms/SampleRoomList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/sample-rooms/add",
        name: "SampleRoomAdd",
        component: () => import("src/views/admin/rooms/SampleRoomAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/sample-room/:id",
        name: "SampleRoomDetail",
        component: () => import("src/views/admin/rooms/SampleRoomDetail"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/rooms/statistics",
        name: "RoomStatistics",
        component: () => import("src/views/admin/rooms/RoomStatistics"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/rooms/keyword",
        name: "RoomKeyword",
        component: () => import("src/views/admin/rooms/RoomKeyword"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/statistics/room/:id",
        name: "StatisticsRoomDetail",
        component: () => import("src/views/admin/rooms/StatisticsRoomDetail"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/statistics/keyword/:id",
        name: "StatisticsKeywordDetail",
        component: () => import("src/views/admin/rooms/StatisticsKeywordDetail"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region Objects
      {
        path: "/objects",
        name: "ObjectList",
        component: () => import("src/views/admin/objects/ObjectList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/objects/add",
        name: "ObjectAdd",
        component: () => import("src/views/admin/objects/ObjectAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/categories",
        name: "CategoriesList",
        component: () => import("src/views/admin/objects/CategoriesList"),
        meta: {
          middleware: [auth],
        },
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "/categories/add",
        name: "CategoriesAdd",
        component: () => import("src/views/admin/objects/CategoriesAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/categories/:id",
        name: "CategoriesDetail",
        component: () => import("src/views/admin/objects/CategoriesDetail"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/object/:id",
        name: "ObjectDetail",
        component: () => import("src/views/admin/objects/ObjectDetail"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region Admin management
      {
        path: "/admins",
        name: "AdminManagement",
        component: () =>
          import("src/views/admin/admin-management/AdminManagement"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/admin/invite",
        name: "AdminInvite",
        component: () => import("src/views/admin/admin-management/AdminInvite"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/admin/edit/:id",
        name: "AdminEdit",
        component: () => import("src/views/admin/admin-management/AdminEdit"),
        meta: {
          middleware: [auth],
        },
      },

      //#endregion

      //#region Area management
      {
        path: "/areas",
        name: "AreaList",
        component: () => import("src/views/admin/area-management/AreaList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/areas/create",
        name: "AreaAdd",
        component: () => import("src/views/admin/area-management/AreaAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/areas/edit/:id",
        name: "AreaEdit",
        component: () => import("src/views/admin/area-management/AreaEdit"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      //#region operator management
      {
        path: "/operator-group",
        name: "OperatorGroupList",
        component: () => import("src/views/admin/operator-management/GroupList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/operator-group/create",
        name: "GroupAdd",
        component: () => import("src/views/admin/operator-management/GroupAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/operator-group/edit/:id",
        name: "GroupEdit",
        component: () => import("src/views/admin/operator-management/GroupEdit"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/operator",
        name: "OperatorList",
        component: () => import("src/views/admin/operator-management/OperatorList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/invite-operator",
        name: "InviteOperatorList",
        component: () => import("src/views/admin/operator-management/InviteOperatorList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/invite-operator/create/:id",
        name: "InviteOperatorAdd",
        component: () => import("src/views/admin/operator-management/InviteOperatorAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/invite-operator/edit/:id",
        name: "InviteOperatorEdit",
        component: () => import("src/views/admin/operator-management/InviteOperatorEdit"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion
    ],
  },
  //#endregion

  //#region Auth
  {
    path: "/",
    component: () => import("src/views/auth/Auth"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("src/views/auth/Login"),
        meta: {
          title: " Login",
        },
      },
      {
        path: "login/verify",
        name: "LoginVerify",
        component: () => import("src/views/auth/LoginVerify"),
        props: true,
        meta: {
          title: " LoginVerify",
        },
      },
      {
        path: "/password/reset",
        name: "PasswordReset",
        component: () => import("src/views/auth/PasswordReset"),
        meta: {
          title: "Reset Password",
        },
      },
    ],
  },
  //#endregion
];

const router = new Router({
  routes,
  base: "/",
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(VueRouteMiddleware());

export default router;
